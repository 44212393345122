<template>
  <div style="min-height: 100%;background-color: #F6F6F6;">
    <van-nav-bar border fixed left-arrow placeholder safe-area-inset-top title="认证" @click-left="onClickLeft"/>
    <div v-if="fail_reason">
      <van-notice-bar :scrollable="false" background="#FFF7F7" color="#F12323" wrapable>
        驳回原因：{{ fail_reason }}
      </van-notice-bar>
    </div>

    <div v-if="choice==0">
      <div class="header padding">填写企业信息</div>
      <div class="pb bg-white">
        <div class="frombox">
          <div class="frombox-left">企业名称</div>
          <input v-model="enterprise.company_name" class="frombox-right" placeholder="请填写执照上的商户名称"
                 type="text">
        </div>
        <div class="frombox">
          <div class="frombox-left">公司地址</div>
          <input v-model="enterprise.company_address" class="frombox-right" placeholder="请输入公司地址" type="text">
        </div>
        <div class="frombox">
          <div class="frombox-left">联系方式</div>
          <input v-model="enterprise.telephone" class="frombox-right" placeholder="请输入联系方式" type="number">
        </div>
        <div class="frombox">
          <div class="frombox-left">社会信用代码</div>
          <input v-model="enterprise.company_icode" class="frombox-right" placeholder="请输入社会信用代码" type="text">
        </div>
        <div class="frombox">
          <div class="frombox-left">法人姓名</div>
          <input v-model="enterprise.legal_name" class="frombox-right" placeholder="请输入法人姓名" type="text">
        </div>
        <div class="frombox">
          <div class="frombox-left">身份证号</div>
          <input v-model="enterprise.id_card" class="frombox-right" placeholder="请输入身份证号" type="text">
        </div>
      </div>
      <div class="header padding">收款账户信息</div>
      <div class="pb bg-white">
        <div class="frombox">
          <div class="frombox-left">开户账号</div>
          <input v-model="enterprise.account_num" class="frombox-right" placeholder="请输入企业开户账户" type="number">
        </div>
        <div class="frombox">
          <div class="frombox-left">开户银行</div>
          <div>
            <input v-model="enterprise.account_bank" class="frombox-right" placeholder="请输入开户行" style="height: 0.6rem;"
                   type="text">
            <div class="frombox-red">（请精确到具体开户行）</div>
          </div>

        </div>
      </div>
      <div class="padding header flex" style="align-items: center;">
        <div>上传凭证</div>
        <div class="frombox-red">（上传的证件照片将仅用于认证）</div>
      </div>
      <div class="padding bg-white">
        <div class="flex">
          <div class="frombox-upload" style="margin-left: 0.46rem;">
            <div class="frombox-upload-box" style="z-index: 2;">
              <van-uploader v-model="IDpositive" :after-read="onIDpositive" :before-read="beforeRead"
                            :class="IDpositive.length==0?'show':''"
                            :max-count="1" accept="image/*" multiple
                            preview-size="2.52rem"/>
            </div>
            <div class="frombox-upload-box bg-gray" style="z-index: 1;">
              <div class="frombox-upload-box-s">
                <van-icon name="plus" size="50"/>
              </div>
              <div class="frombox-upload-box-txt">添加身份证正面</div>
            </div>
          </div>
          <div class="frombox-upload" style="margin-left: 0.86rem;">
            <div class="frombox-upload-box" style="z-index: 2;">
              <van-uploader v-model="IDside" :after-read="onIDside" :before-read="beforeRead" :class="IDside.length==0?'show':''"
                            :max-count="1"
                            accept="image/*" multiple preview-size="2.52rem"/>
            </div>
            <div class="frombox-upload-box bg-gray" style="z-index: 1;">
              <div class="frombox-upload-box-s">
                <van-icon name="plus" size="50"/>
              </div>
              <div class="frombox-upload-box-txt">添加身份证反面</div>
            </div>
          </div>
        </div>
        <div class="frombox-upload" style="margin-left: 0.46rem;margin-top: 0.58rem;">
          <div class="frombox-upload-box" style="z-index: 2;">
            <van-uploader v-model="Business" :after-read="onBusiness" :before-read="beforeRead" :class="Business.length==0?'show':''"
                          :max-count="1"
                          accept="image/*" multiple preview-size="2.52rem"/>
          </div>

          <div class="frombox-upload-box bg-gray" style="z-index: 1;">
            <div class="frombox-upload-box-s">
              <van-icon name="plus" size="50"/>
            </div>
            <div class="frombox-upload-box-txt">上传营业执照</div>
          </div>
        </div>
      </div>
      <div v-if="modify==undefined" class="upload-sub" @click="onSubmit">提交</div>
      <div v-if="modify==undefined" class="padding"></div>
    </div>
    <div v-if="choice==1">
      <div class="header padding">填写企业信息</div>
      <div class="pb bg-white">
        <div class="frombox">
          <div class="frombox-left">个体户名称</div>
          <input v-model="businessCircles.company_name" class="frombox-right" placeholder="请填写执照上的商户名称"
                 type="text">
        </div>
        <div class="frombox">
          <div class="frombox-left">法人姓名</div>
          <input v-model="businessCircles.legal_name" class="frombox-right" placeholder="请输入法人姓名" type="text">
        </div>
        <div class="frombox">
          <div class="frombox-left">身份证号</div>
          <input v-model="businessCircles.id_card" class="frombox-right" placeholder="请输入身份证号" type="text">
        </div>
        <div class="frombox">
          <div class="frombox-left">联系方式</div>
          <input v-model="businessCircles.telephone" class="frombox-right" placeholder="请输入联系方式"
                 type="number">
        </div>
        <div class="frombox">
          <div class="frombox-left">纳税人识别号</div>
          <input v-model="businessCircles.company_icode" class="frombox-right" placeholder="请输入纳税人识别号"
                 type="text">
        </div>
        <div class="frombox">
          <div class="frombox-left">经营地址</div>
          <input v-model="businessCircles.company_address" class="frombox-right" placeholder="请输入经营地址"
                 type="text">
        </div>
      </div>
      <div class="header padding">收款账户信息</div>
      <div class="pb bg-white">
        <div class="frombox">
          <div class="frombox-left">开户账号</div>
          <input v-model="businessCircles.account_num" class="frombox-right" placeholder="请输入企业开户账户"
                 type="number">
        </div>
        <div class="frombox">
          <div class="frombox-left">开户银行</div>
          <div>
            <input v-model="businessCircles.account_bank" class="frombox-right" placeholder="请输入开户行" style="height: 0.6rem;"
                   type="text">
            <div class="frombox-red">（请精确到具体开户行）</div>
          </div>

        </div>
      </div>
      <div class="padding header flex" style="align-items: center;">
        <div>上传凭证</div>
        <div class="frombox-red">（上传的证件照片将仅用于认证）</div>
      </div>
      <div class="padding bg-white">
        <div class="flex">
          <div class="frombox-upload" style="margin-left: 0.46rem;">
            <div class="frombox-upload-box" style="z-index: 2;">
              <van-uploader v-model="IDpositive" :after-read="onIDpositive" :before-read="beforeRead"
                            :class="IDpositive.length==0?'show':''"
                            :max-count="1" accept="image/*" multiple
                            preview-size="2.52rem"/>
            </div>

            <div class="frombox-upload-box bg-gray" style="z-index: 1;">
              <div class="frombox-upload-box-s">
                <van-icon name="plus" size="50"/>
              </div>
              <div class="frombox-upload-box-txt">添加身份证正面</div>
            </div>
          </div>
          <div class="frombox-upload" style="margin-left: 0.86rem;">
            <div class="frombox-upload-box" style="z-index: 2;">
              <van-uploader v-model="IDside" :after-read="onIDside" :before-read="beforeRead" :class="IDside.length==0?'show':''"
                            :max-count="1"
                            accept="image/*" multiple preview-size="2.52rem"/>
            </div>

            <div class="frombox-upload-box bg-gray" style="z-index: 1;">
              <div class="frombox-upload-box-s">
                <van-icon name="plus" size="50"/>
              </div>
              <div class="frombox-upload-box-txt">添加身份证反面</div>
            </div>
          </div>
        </div>
        <div class="frombox-upload" style="margin-left: 0.46rem;margin-top: 0.58rem;">
          <div class="frombox-upload-box" style="z-index: 2;">
            <van-uploader v-model="Business" :after-read="onBusiness" :before-read="beforeRead" :class="Business.length==0?'show':''"
                          :max-count="1"
                          accept="image/*" multiple preview-size="2.52rem"/>
          </div>

          <div class="frombox-upload-box bg-gray" style="z-index: 1;">
            <div class="frombox-upload-box-s">
              <van-icon name="plus" size="50"/>
            </div>
            <div class="frombox-upload-box-txt">上传营业执照</div>
          </div>
        </div>
      </div>
      <div v-if="modify==undefined" class="upload-sub" @click="onSubmitBusinessCircles">提交</div>
      <div v-if="modify==undefined" class="padding"></div>
    </div>
    <div v-if="choice==2">
      <div class="header padding">填写个人信息</div>
      <div class="pb bg-white">
        <div class="frombox">
          <div class="frombox-left">真实姓名</div>
          <input v-model="personal.real_name" class="frombox-right" placeholder="请填写真实姓名"  type="text">
        </div>
        <div class="frombox">
          <div class="frombox-left">身份证号</div>
          <input v-model="personal.id_card" class="frombox-right" placeholder="请输入身份证号"  type="text">
        </div>
        <div class="frombox">
          <div class="frombox-left">开户银行</div>
          <div>
            <input v-model="personal.account_bank" class="frombox-right" placeholder="请输入开户银行"
                   type="text">
          </div>
        </div>
        <div class="frombox">
          <div class="frombox-left">银行账号</div>
          <input v-model="personal.account_num" class="frombox-right" placeholder="请输入银行账号" type="text">
        </div>
        <div class="frombox">
          <div class="frombox-left">手机号</div>
          <input v-model="personal.phone" class="frombox-right" placeholder="请输入手机号" type="number">
        </div>
      </div>
      <div class="padding header flex" style="align-items: center;">
        <div>上传凭证</div>
        <div class="frombox-red">（上传的证件照片将仅用于认证）</div>
      </div>
      <div class="padding bg-white">
        <div class="flex">
          <div class="frombox-upload" style="margin-left: 0.46rem;">
            <div class="frombox-upload-box" style="z-index: 2;">
              <van-uploader v-model="IDpositive" :after-read="onIDpositive" :before-read="beforeRead"
                            :class="IDpositive.length==0?'show':''"
                            :max-count="1" accept="image/jpeg" multiple
                            preview-size="2.52rem"/>
            </div>

            <div class="frombox-upload-box bg-gray" style="z-index: 1;">
              <div class="frombox-upload-box-s">
                <van-icon name="plus" size="50"/>
              </div>
              <div class="frombox-upload-box-txt">添加身份证正面</div>
            </div>
          </div>
          <div class="frombox-upload" style="margin-left: 0.86rem;">
            <div class="frombox-upload-box" style="z-index: 2;">
              <van-uploader v-model="IDside" :after-read="onIDside" :before-read="beforeRead" :class="IDside.length==0?'show':''"
                            :max-count="1"
                            accept="image/jpeg" multiple preview-size="2.52rem"/>
            </div>
            <div class="frombox-upload-box bg-gray" style="z-index: 1;">
              <div class="frombox-upload-box-s">
                <van-icon name="plus" size="50"/>
              </div>
              <div class="frombox-upload-box-txt">添加身份证反面</div>
            </div>
          </div>
<!--          <input type="file">-->
        </div>
      </div>
      <div v-if="modify==undefined" class="upload-sub" @click="onSubmitXieyi">提交</div>
      <div v-if="modify==undefined" class="padding"></div>
    </div>
    <!-- 修改 -->
    <div v-if="modify==1" class="padding flex bg-white" style="justify-content: space-around;">
      <div class="modify-box modify-box-gray" @click="onDelete">取消认证</div>
      <div class="modify-box modify-box-red" @click="onConfirm">修改</div>
    </div>
    <van-popup v-model="agreementShow" :style="{ height: '70%' }" closeable position="bottom" round>
      <div class="xieyiPopup">
        <div class="xieyitille">
          服务合作协议
        </div>
        <p class="xieyiInfo">
          感谢您选择灵活用工平台！

          本协议向下甲方指灵活用工平台运营方 湖南诚盛德科技有限公司 ，乙方指您。
        </p>
        <p class="xieyiCu">
          【特别提示】<br>
          为维护您的自身权益，请您在签署协议前仔细阅读、充分理解本协议的全部条款及内容，如有任何疑问，请您及时向客服人员进行询问，客服人员将对您解释条款内容；如您不同意本协议任何内容或无法理解条款内容，请立即停止申请/注册/签署程序。如您已按照提示填写个人信息、阅读并同意本协议且完成全部申请或书面签署本协议，即表示您已充分阅读、理解并接受本协议的全部内容，并与灵活用工平台达成一致，成为灵活用工平台的合作人员。届时您不应以未阅读/不理解本协议的内容或其他类似缘由主张本协议无效/要求撤销本协议。
        </p>
        <p class="xieyiInfo">
          【协议范围】<br>
          1、本协议由您和“灵活用工平台”运营方（湖南诚盛德科技有限公司 ）签订，本协议具有合同效力。<br>
          2、除另行明确声明外，灵活用工平台合作包含任何灵活用工平台及其关联公司、第三方合作商提供的基于互联网及移动互联网的相关合作，且均受本协议约束。<br>
        </p>
        <p class="xieyiInfo">
          【协议条款】<br>
          根据国家法律法规相关规定，您与灵活用工平台在平等自愿、协商一致、诚实互信的基础上，就灵活用工平台选择您与灵活用工平台合作方_湖南诚盛德科技有限公司进行合作获取合作费用，灵活用工平台向您提供技术合作事宜订立本协议。
        </p>
        <p class="xieyiInfo">
          一、合作内容<br>
          1、您应在理解、承诺遵守灵活用工平台与合作方相关协议约定、合作方制定的合作/政策规则和合作方指导、安排与要求（上诉内容包括但不限于平台规则、用户注册协议，您对合作方提供的合作内容、合作方式、合作流程、合作规范、培训学习、合作费结算以及您要求了解的其他与合作相关的必要知悉情况）的基础上向合作方提供/完成相关合作。您在申请/注册/签署本协议前应充分对灵活用工平台及合作方进行询问，确保知悉并理解相关规定内容。<br>
          2、灵活用工平台向您提供在线合同（电签）、在线实名认证、合作费查询、咨询等技术信息合作。
        </p>
        <p class="xieyiInfo">
          二、协议效力<br>
          1、您承诺并保证您所提供的信息（包括但不限于姓名、身份证号、联系方式、银行卡号等）真实、准确、有效、合法，不会影响本协议效力。如信息变更（如联系方式、银行卡号或不再是自由职业者身份等）应当及时通知灵活用工平台。若无及时告知灵活用工平台，与此有关的全部法律责任或损失将由您负责/承担。<br>
          2、基于准备和开展合作的目的，按合作方相关规定所必须注册/申请/签署的协议与本协议同时有效，如二者有冲突，以前者为准，但不得损害灵活用工平台利益。<br>
          3、本协议自您申请/注册/签署之日起1年内有效。期限届满，双方应另行签署新的协议，如双方未签署新协议，而以实际行动继续履行本协议内容的，视为双方同意本协议期限顺延直至按照本协议约定终止或解除时为止。<br>
          4、若合作方发生主体变更，您在收到灵活用工平台关于主体变更通知后24小时内未按照通知内容回复或合作方主体变更后您仍以实际行动继续为新的合作方提供相同合作的，视为您同意合作方主体变更。<br>

          5、本协议在发生下列情形时自然终止：<br>

          a、协议期限届满双方未签署新协议，且不再继续履行本协议内容的；<br>

          b、灵活用工平台与合作方变更业务合作模式或终止合作，而不需要您继续履行本协议的；<br>

          c、经合作方事先同意后，您主动终止合作的；<br>

          d、合作方认为您不合适继续提供合作的；<br>

          e、其他原因导致本协议不可能或不适合再继续履行的。<br>

          6、本协议终止时，您应按照合作方要求办理必要的工作交接手续（如有）。<br>

          7、本协议的提前终止不影响已经产生的权利义务关系。<br>
        </p>
        <p class="xieyiInfo">
          三、权利及义务<br>

          1、灵活用工平台保证具备履行本协议所需的合法资质，并且与合作方签订的相关协议真实、合法、有效。

          2、灵活用工平台依约向您提供在线合同、线上实名认证等技术信息合作，并向您发放合作费。如您的合作费涉及个人所得税，灵活用工平台将根据国家相关法律规定及灵活用工平台主管税务机关的要求为您代扣代缴个人所得税。

          3、合作期间，您应根据平台规定，遵守合作方政策（包括但不限于合作内容、合作方式、合作流程、合作规范、培训学习、合作费结算等），根据合作方指导、安排和要求，提供/完成相关合作并达到合作方规定的质量标准，且合作期间您应与灵活用工平台或合作方保持必要的沟通联络。

          4、合作方有权根据本协议合作内容条款规定，对您的合作进行检查和验收，您应配合合作方的检查和验收，并根据合作方改进意见不断改进或优化合作。

          5、合作方可能根据自身业务及运营情况、检查验收结果随时对您的合作内容进行调整，您应理解并同意接受此种变更，并根据调整内容不断改进或优化合作。

          6、您在合作中除使用合作方提供的图片、文字、音频、视频、数据等合作所需资料信息外，您应承诺其自主提供的合作及资料信息均为原创或拥有合法授权，内容合法健康，不违反任何应予适用的法律、法规及政策,
          不会侵犯任何第三方权利，如因此给灵活用工平台或合作方造成损失，您应承担全部责任。

          7、您在与合作方合作过程中，须维护灵活用工平台和合作方的品牌、形象和声誉，并对自己的言论、行为负责。

          8、除本协议约定的您合作内容外，您不能以灵活用工平台或合作方名义开展任何与合作内容无关的业务或活动。

          9、您在合作过程中因过错致人损害或损失的，您应承担赔偿责任。

          10、您应知晓并确认，您与灵活用工平台通过本协议建立的是合作关系，适用《中华人民共和国合同法》、《民法总则》等民事法律。您承诺并保证无意通过本协议与灵活用工平台建立劳动合同关系，且申请/注册/签署本协议也无法成为灵活用工平台的在职职工。基于以上，灵活用工平台不承担您任何社会保险、公积金、残保金和福利待遇。
        </p>
        <p class="xieyiInfo">
          四、费用支付<br>

          1、灵活用工平台收到合作方支付的合作款项后，将按照灵活用工平台与合作方的协议内容及您向合作方提供的实际合作内容、合作方的评价、合作方的结算规则向您支付合作费。<br>

          2、您知晓并确认，在本协议有效期限内，因合作方的业务结算规则根据运营需要会浮动调整；业务结算规则调整后，您以实际行动表明继续履行本协议的，视为您同意调整后的业务结算规则。<br>

          3、您知晓并确认，由于您所提供的合作质量、合作方的运营情况等的不同或变化，您收到的合作费可能会浮动，该浮动为正常情况。<br>
          4、您可针对电签、在线实名认证、合作费发放时间和发放金额等问题向灵活用工平台进行咨询。<br>

          5、灵活用工平台支付您的合作费以您提供的实名银行账户为准，因您提供收款账户不实造成的一切损失由您自行承担；如您收款账户变更或发生不可用等情况时，应及时进行变更操作并通知灵活用工平台，否则由此造成的一切损失由您自行承担。<br>

          6、您理解并确认，作为灵活用工平台合作人员，只有在灵活用工平台收到合作方相应合作款项后，方可向灵活用工平台要求支付合作费，且不应直接向合作方要求支付合作费。您向合作方发起的申请支付的行为，应视为向灵活用工平台发起，而非向合作方发起。<br>
        </p>
        <p class="xieyiInfo">
          五、保密责任<br>

          1、灵活用工平台可能将您的个人经营信息（包括但不限于身份、账户、联系方式等）与合作方、灵活用工平台主管税务机关分享，主要用于保障为您提供的合作（如合作费发放、代扣代缴个人所得税、完税证明开具等）顺利完成。除前述情况或其他法定原因外，灵活用工平台不得因任何目的、在任何时间、以任何方式，与本平台以外的任何公司、组织和个人共享/转让您的个人信息。<br>

          2、协议期间内，您应在灵活用工平台及合作方许可范围内使用合作方的商业信息。协议期间内及协议期限届满后任何时间，您均有义务对合作过程中知悉的灵活用工平台及合作方相关信息（包括但不限于您与灵活用工平台签订的本协议条款内容、合作费用、本协议履行情况及合作过程中了解的合作方知识产权情况、经营模式及经营状况、产品信息、价格标准、相关合作政策、合作对象信息等）予以保密，未经灵活用工平台及合作方书面许可，您不得因任何目的、在任何时间、以任何方式对其他方披露、给予或转让前述保密信息。否则，因此造成的灵活用工平台及合作方的一切损失均须由您承担/赔偿。<br>
        </p>
        <p class="xieyiInfo">
          六、涉税事务委托授权<br>

          1、您须按照国家相关法律法规及灵活用工平台主管税务机关要求，委托灵活用工平台代理本协议交易项下的涉税事务，如代扣代缴个人所得税等。
        </p>
        <p class="xieyiInfo">
          七、违约责任及争议解决<br>

          1、甲乙双方应按本协议约定内容行使权力和义务，如一方违反本协议，另一方有权要求对方及时改正；如造成对方损害或损失，守约方有权要求违约方赔偿。<br>

          2、因不可抗力造成损失的，彼此不负赔偿责任，但发生不可抗力一方应及时将有关情况通知另一方，并尽最大努力进行补救。本协议所称不可抗力是指不能预见、不能克服并不能避免且对一方当事人造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、火灾和风暴等以及社会事件如战争、动乱、政府行为、黑客事件、大范围电信事故等。<br>

          3、因国家法律法规、相关政策调整等不可抗力因素，造成业务无法继续开展的，乙方应积极配合甲方处理相关事宜，对此甲方不承担任何责任。<br>

          4、因履行本协议发生争议，双方应友好协商解决；协商不成应提请甲方所在地人民法院诉讼解决。<br>
        </p>
        <p class="xieyiCu">
          【提示条款】<br>

          如您不同意灵活用工平台告知内容、不同意本协议任何内容或无法理解本协议条款内容，请您立即停止任何的申请/注册/签署程序。<br>

          当您按照提示填写个人信息、阅读并同意本协议且完成全部申请或书面签署本协议后，即表示您已充分阅读、理解并接受本协议的全部内容，与灵活用工平台达成一致意见，您成为灵活用工平台的合作人员。协议签署后，您不
          应以未阅读/不理解本协议的内容或者未获得灵活用工平台对您问询的解答等理由，主张本协议无效或要求撤销本协议。
        </p>

      </div>
      <div class="xieyibtn">
        <div class="btn" @click="onSubmitPersonal">
          同意协议
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      agreementShow: false,
      Personal1: 1,
      choice: 0, //0==企业，1==个人  2==无法提供发票
      IDpositive: [], //身份证正面
      IDside: [], //身份证反面
      Business: [], //营业执照
      openid: 1,
      login_token: localStorage.getItem("login_token"),
      modify: 0,
      fail_reason: '',//失败原因
      enterprise: {
        company_name: '', //企业名称
        company_address: '', //公司地址
        telephone: '', //联系电话
        company_icode: '', //社会信用代码
        legal_name: '', //法人姓名
        id_card: '', //法人身份证号码
        account_bank: '', //开户银行
        account_num: '', //银行卡号
        auth_photos: [], //认证照片集合json
      }, //企业认证内容
      businessCircles: {
        company_name: '', //个体工商户名称
        company_address: '', //公司地址
        telephone: '', //联系电话
        company_icode: '', //社会信用代码
        legal_name: '', //法人姓名
        id_card: '', //法人身份证号码
        account_bank: '', //开户银行
        account_num: '', //银行卡号
        auth_photos: [], //认证照片集合json
      }, //个体工商户
      personal: {
        real_name: '', //真实姓名
        id_card: '', //身份证号
        id_card_front: '', //身份证正面照
        id_card_back: '', //身份证反面照
        account_bank: '', //开户银行
        account_num: '', //银行卡号
        phone: "",
      }, //没有发票

    }
  },
  mounted() {
    var _this = this;
    _this.choice = _this.$route.query.choice; //判断来路
    _this.modify = _this.$route.query.modify; //修改来路
    if (_this.$route.query.modify == 1) { //修改
      _this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });
      var modifyurl = '';
      var myData = new URLSearchParams();
      myData.append("openid", _this.openid);
      myData.append("login_token", _this.login_token);
      if (_this.$route.query.choice == 2) { //无发票
        modifyurl = "users/api.finance/get_person_auth_info"
      } else { //企业----个体户
        modifyurl = "users/api.finance/get_company_auth_info"
      }
      _this.$http({
        method: 'post',
        url: modifyurl,
        data: myData
      }).then((res) => {
        _this.$toast.clear();
        console.log(res)
        if (res.data.code == 0) {
          if (_this.choice == 0) { //企业
            _this.enterprise.company_name = res.data.auth_info.company_name; //企业名称
            _this.enterprise.company_address = res.data.auth_info.company_address; //公司地址
            _this.enterprise.telephone = res.data.auth_info.real_telephone; //联系电话
            _this.enterprise.company_icode = res.data.auth_info.company_icode; //社会信用代码
            _this.enterprise.legal_name = res.data.auth_info.legal_name; //法人姓名
            _this.enterprise.id_card = res.data.auth_info.real_id_card; //法人身份证号码
            _this.enterprise.account_bank = res.data.bank_info.account_bank; //开户银行
            _this.enterprise.account_num = res.data.bank_info.real_account_num; //银行卡号
            const str = res.data.auth_info.auth_photos;
            const auth_photos = str.split(",");
            _this.enterprise.auth_photos = auth_photos
            _this.IDpositive = [{
              url: auth_photos[0]
            }]
            _this.IDside = [{
              url: auth_photos[1]
            }]
            _this.Business = [{
              url: auth_photos[2]
            }]
            _this.fail_reason = res.data.auth_info.fail_reason;//失败原因
          } else if (_this.choice == 1) { //个体户
            _this.businessCircles.company_name = res.data.auth_info.company_name; //企业名称
            _this.businessCircles.company_address = res.data.auth_info.company_address; //公司地址
            _this.businessCircles.telephone = res.data.auth_info.real_telephone; //联系电话
            _this.businessCircles.company_icode = res.data.auth_info.company_icode; //社会信用代码
            _this.businessCircles.legal_name = res.data.auth_info.legal_name; //法人姓名
            _this.businessCircles.id_card = res.data.auth_info.real_id_card; //法人身份证号码
            _this.businessCircles.account_bank = res.data.bank_info.account_bank; //开户银行
            _this.businessCircles.account_num = res.data.bank_info.real_account_num; //银行卡号
            const str = res.data.auth_info.auth_photos;
            const auth_photos = str.split(",");
            _this.businessCircles.auth_photos = auth_photos;
            _this.IDpositive = [{
              url: auth_photos[0]
            }]
            _this.IDside = [{
              url: auth_photos[1]
            }]
            _this.Business = [{
              url: auth_photos[2]
            }]
            _this.fail_reason = res.data.auth_info.fail_reason;//失败原因

          } else if (_this.choice == 2) { //无发票
            _this.personal.real_name = res.data.auth_info.real_real_name; //真实姓名
            _this.personal.id_card = res.data.auth_info.real_id_card; //身份证号
            _this.personal.id_card_front = res.data.auth_info.id_card_front; //身份证正面照
            _this.personal.id_card_back = res.data.auth_info.id_card_back; //身份证反面照
            _this.personal.account_bank = res.data.bank_info.account_bank; //开户银行
            _this.personal.account_num = res.data.bank_info.real_account_num; //银行卡号
            _this.personal.phone = res.data.auth_info.real_phone;//手机号码
            _this.IDpositive = [{
              url: res.data.auth_info.id_card_front
            }]
            _this.IDside = [{
              url: res.data.auth_info.id_card_back
            }]
            _this.fail_reason = res.data.auth_info.fail_reason;//失败原因
          }
        } else if (res.data.code == -100) {
          _this.$dialog.alert({
            title: '提示',
            message: '登录过期，请重新登录',
          }).then(() => {
            _this.$router.push({
              path: "/login"
            })
          });

        } else {
          _this.$toast(res.data.msg);
        }
      }).catch(() => {
        _this.$toast.clear();
        _this.$toast('网络请求失败');
      });
    } else {
      if (_this.choice == 2) {
        var myData1 = new URLSearchParams();
        myData1.append("openid", _this.openid);
        myData1.append("login_token", _this.login_token);
        _this.$http({
          method: 'post',
          url: "/users/api.finance/getUserRealInfo",
          data: myData1
        }).then(res => {
          if (res.data.code == 0) {
            var data = res.data.data
            if (!data.real_name || !data.id_card) {
              this.$toast("您还未实名认证，请先认证");
            }
            _this.personal.real_name = data.real_name;
            _this.personal.id_card = data.id_card;
            _this.personal.phone = data.phone;

          } else {
            this.$toast("您还未实名认证，请先认证");
          }
        })
      }

    }
  },
  methods: {
    // 协议确认
    onSubmitXieyi() {
      var _this = this;
      var personal = _this.personal;
      if (personal.real_name == '') {
        _this.$toast('请输入真实姓名');
      } else if (personal.id_card == '') {
        _this.$toast('请输入身份证号');
      } else if (personal.account_bank == '') {
        _this.$toast('请输入开户银行');
      } else if (personal.account_num == '') {
        _this.$toast('请输入银行卡号');
      } else if (personal.id_card_front == '') {
        _this.$toast('请上传身份证正面照');
      } else if (personal.id_card_back == '') {
        _this.$toast('请上传身份证反面照');
      } else if (_this.IDpositive.length == 0) {
        _this.$toast('请上传身份证正面照片');
      } else if (_this.IDside.length == 0) {
        _this.$toast('请上传身份证反面照片');
      } else if (personal.phone == '') {
        _this.$toast('请输入联系电话');
      } else if (!(/^1[3456789]\d{9}$/.test(personal.phone))) {
        _this.$toast('请输入正确的联系电话');
      } else {
        // this.agreementShow = true
        this.Personal1 = 1
        this.onSubmitPersonal()
      }
    },
    //返回
    onClickLeft() {
      if (window.history.length <= 1) {
        this.$router.push({
          path: '/'
        })
        return false
      } else {
        this.$router.go(-1)
      }
    },
    beforeRead(file) { //上传之前校验
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        this.$toast('只允许上传jpg/png格式的图片！')
        return false
      }
      return true
    },
    //上传身份证正面
    onIDpositive(file) {
      var _this = this
      file.status = 'uploading';
      file.message = '上传中...';
      var myData = new FormData();
      myData.append('file', file.file);
      _this.$http({
        method: 'post',
        url: 'index/api.upload/file',
        data: myData,
        headers: { //添加请求头
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        if (res.data.uploaded) {
          file.status = '';
          file.message = '';
          if (_this.choice == 0) { //企业
            _this.enterprise.auth_photos[0] = res.data.url
          } else if (_this.choice == 1) { //个体工商户
            _this.businessCircles.auth_photos[0] = res.data.url
          } else { //没有发票
            _this.personal.id_card_front = res.data.url
          }


        } else {
          file.status = 'failed';
          file.message = '上传失败';
        }
      }).catch(() => {
        _this.$toast('网络请求失败');
      });


    },
    //上传身份证反面
    onIDside(file) {
      var _this = this
      file.status = 'uploading';
      file.message = '上传中...';
      var myData = new FormData();
      myData.append('file', file.file);
      _this.$http({
        method: 'post',
        url: 'index/api.upload/file',
        data: myData,
        headers: { //添加请求头
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        if (res.data.uploaded) {
          file.status = '';
          file.message = '';
          if (_this.choice == 0) { //企业
            _this.enterprise.auth_photos[1] = res.data.url
          } else if (_this.choice == 1) { //个体工商户
            _this.businessCircles.auth_photos[1] = res.data.url
          } else { //没有发票
            _this.personal.id_card_back = res.data.url
          }


        } else {
          file.status = 'failed';
          file.message = '上传失败';
        }
      }).catch(() => {
        _this.$toast('网络请求失败');
      });
    },
    //营业执照
    onBusiness(file) {
      var _this = this
      file.status = 'uploading';
      file.message = '上传中...';
      var myData = new FormData();
      myData.append('file', file.file);
      _this.$http({
        method: 'post',
        url: 'index/api.upload/file',
        data: myData,
        headers: { //添加请求头
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        if (res.data.uploaded) {
          file.status = '';
          file.message = '';
          if (_this.choice == 0) { //企业
            _this.enterprise.auth_photos[2] = res.data.url
          } else if (_this.choice == 1) { //个体工商户
            _this.businessCircles.auth_photos[2] = res.data.url
          }


        } else {
          file.status = 'failed';
          file.message = '上传失败';
        }
      }).catch(() => {
        _this.$toast('网络请求失败');
      });
    },
    //企业提交认证
    onSubmit() {
      var _this = this;
      var enterprise = _this.enterprise;
      if (enterprise.company_name == '') {
        _this.$toast('请输入企业名称');
      } else if (enterprise.company_address == '') {
        _this.$toast('请输入公司地址');
      } else if (enterprise.telephone == '') {
        _this.$toast('请输入联系电话');
      } else if (!(/^1[34578]\d{9}$/.test(enterprise.telephone))) {
        _this.$toast('请输入正确的联系电话');
      } else if (enterprise.company_icode == '') {
        _this.$toast('请输入社会信用代码');
      } else if (enterprise.legal_name == '') {
        _this.$toast('请输入法人姓名');
      } else if (enterprise.id_card == '') {
        _this.$toast('请输入法人身份证号码');
      } else if (enterprise.account_bank == '') {
        _this.$toast('请输入开户银行');
      } else if (enterprise.account_num == '') {
        _this.$toast('请输入开户账号');
      } else if (_this.IDpositive.length == 0) {
        _this.$toast('请上传身份证正面照片');
      } else if (_this.IDside.length == 0) {
        _this.$toast('请上传身份证反面照片');
      } else if (_this.Business.length == 0) {
        _this.$toast('请上传营业执照照片');
      } else {
        _this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0
        });
        var myData = new URLSearchParams();
        myData.append("openid", _this.openid);
        myData.append("login_token", _this.login_token);
        myData.append("invoice_type", "1");
        myData.append("company_type", "2");
        myData.append("company_name", enterprise.company_name);
        myData.append("company_address", enterprise.company_address);
        myData.append("telephone", enterprise.telephone);
        myData.append("company_icode", enterprise.company_icode);
        myData.append("legal_name", enterprise.legal_name);
        myData.append("id_card", enterprise.id_card);
        myData.append("auth_photos", enterprise.auth_photos);
        myData.append("account_bank", enterprise.account_bank);
        myData.append("account_num", enterprise.account_num);
        _this.$http({
          method: 'post',
          url: 'users/api.finance/company_auth',
          data: myData
        }).then((res) => {
          _this.$toast.clear();
          if (res.data.code == 0) {
            _this.$dialog.alert({
              title: '提示',
              message: res.data.msg,
            }).then(() => {
              _this.$router.push({
                path: "/realname/service/service"
              })
            });
          } else if (res.data.code == -100) {
            _this.$dialog.alert({
              title: '提示',
              message: '登录过期，请重新登录',
            }).then(() => {
              _this.$router.push({
                path: "/login"
              })
            });

          } else {
            _this.$toast(res.data.msg);
          }
        }).catch(() => {
          _this.$toast.clear();
          _this.$toast('网络请求失败');
        });

      }


    },
    //个体工商户提交
    onSubmitBusinessCircles() {
      var _this = this;
      var businessCircles = _this.businessCircles;
      if (businessCircles.company_name == '') {
        _this.$toast('请输入个体户名称');
      } else if (businessCircles.company_address == '') {
        _this.$toast('请输入经营地址');
      } else if (businessCircles.telephone == '') {
        _this.$toast('请输入联系电话');
      } else if (!(/^1[3456789]\d{9}$/.test(businessCircles.telephone))) {
        _this.$toast('请输入正确的联系电话');
      } else if (businessCircles.company_icode == '') {
        _this.$toast('请输入社会信用代码');
      } else if (businessCircles.legal_name == '') {
        _this.$toast('请输入法人姓名');
      } else if (businessCircles.id_card == '') {
        _this.$toast('请输入法人身份证号码');
      } else if (businessCircles.account_bank == '') {
        _this.$toast('请输入开户银行');
      } else if (businessCircles.account_num == '') {
        _this.$toast('请输入开户账号');
      } else if (_this.IDpositive.length == 0) {
        _this.$toast('请上传身份证正面照片');
      } else if (_this.IDside.length == 0) {
        _this.$toast('请上传身份证反面照片');
      } else if (_this.Business.length == 0) {
        _this.$toast('请上传营业执照照片');
      } else {
        _this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0
        });
        var myData = new URLSearchParams();
        myData.append("openid", _this.openid);
        myData.append("login_token", _this.login_token);
        myData.append("invoice_type", "1");
        myData.append("company_type", "3");
        myData.append("company_name", businessCircles.company_name);
        myData.append("company_address", businessCircles.company_address);
        myData.append("telephone", businessCircles.telephone);
        myData.append("company_icode", businessCircles.company_icode);
        myData.append("legal_name", businessCircles.legal_name);
        myData.append("id_card", businessCircles.id_card);
        myData.append("auth_photos", businessCircles.auth_photos);
        myData.append("account_bank", businessCircles.account_bank);
        myData.append("account_num", businessCircles.account_num);
        _this.$http({
          method: 'post',
          url: 'users/api.finance/company_auth',
          data: myData
        }).then((res) => {
          _this.$toast.clear();
          if (res.data.code == 0) {
            _this.$dialog.alert({
              title: '提示',
              message: res.data.msg,
            }).then(() => {
              _this.$router.push({
                path: "/realname/service/service"
              })
            });

          } else if (res.data.code == -100) {
            _this.$dialog.alert({
              title: '提示',
              message: '登录过期，请重新登录',
            }).then(() => {
              _this.$router.push({
                path: "/login"
              })
            });

          } else {
            _this.$toast(res.data.msg);
          }
        }).catch(() => {
          _this.$toast.clear();
          _this.$toast('网络请求失败');
        });

      }

    },
    //没有发票提交
    onSubmitPersonal() {
      var _this = this, url = "";
      var personal = _this.personal;
      var myData = new URLSearchParams();
      if (this.Personal1 == 1) {
        _this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0
        });
        myData.append("openid", _this.openid);
        myData.append("login_token", _this.login_token);
        myData.append("invoice_type", "2");
        myData.append("company_type", "1");
        myData.append("real_name", personal.real_name);
        myData.append("id_card", personal.id_card);
        myData.append("account_bank", personal.account_bank);
        myData.append("account_num", personal.account_num);
        myData.append("id_card_front", personal.id_card_front);
        myData.append("id_card_back", personal.id_card_back);
        myData.append("phone", personal.phone);
        console.log(personal)
        // return
        _this.$http({
          method: 'post',
          url: 'users/api.finance/person_auth',
          data: myData
        }).then((res) => {
          _this.$toast.clear();
          if (res.data.code == 0) {
            _this.$dialog.alert({
              title: '提示',
              message: res.data.msg,
            }).then(() => {
              _this.$router.push({
                path: "/realname/service/service"
              })
            });
          } else if (res.data.code == -100) {
            this.agreementShow = false
            _this.$dialog.alert({
              title: '提示',
              message: '登录过期，请重新登录',
            }).then(() => {
              _this.$router.push({
                path: "/login"
              })
            });

          } else {
            this.agreementShow = false
            _this.$toast(res.data.msg);
          }
        }).catch(() => {
          this.agreementShow = false
          _this.$toast.clear();
          _this.$toast('网络请求失败');
        });
      } else {
        url = "users/api.finance/update_person_auth";
        myData.append("openid", _this.openid);
        myData.append("login_token", _this.login_token);
        myData.append("real_name", personal.real_name);
        myData.append("id_card", personal.id_card);
        myData.append("account_bank", personal.account_bank);
        myData.append("account_num", personal.account_num);
        myData.append("id_card_front", personal.id_card_front);
        myData.append("id_card_back", personal.id_card_back);
        myData.append("phone", personal.phone);
        _this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0
        });
        _this.$http({
          method: 'post',
          url: url,
          data: myData
        }).then((res) => {
          _this.$toast.clear();
          if (res.data.code == 0) {
            _this.$dialog.alert({
              title: '提示',
              message: '已提交，正在审核中',
            }).then(() => {
              _this.$router.push({
                path: "/realname/service/service"
              })
            });
          } else if (res.data.code == -100) {
            _this.$dialog.alert({
              title: '提示',
              message: '登录过期，请重新登录',
            }).then(() => {
              _this.$router.push({
                path: "/login"
              })
            });

          } else {
            _this.$toast(res.data.msg);
          }
        }).catch(() => {
          _this.$toast.clear();
          _this.$toast('网络请求失败');
        });
      }
    },
    //修改
    onConfirm() {
      var _this = this;
      var choice = _this.choice;
      var url = '';
      var myData = new URLSearchParams();
      myData.append("openid", _this.openid);
      myData.append("login_token", _this.login_token);
      console.log(_this.personal)
      console.log('企业', _this.enterprise)
      console.log('个体户', _this.businessCircles)

      if (choice == 0) { //企业
        url = "users/api.finance/update_company_auth";
        var enterprise = _this.enterprise;
        if (enterprise.company_name == '') {
          _this.$toast('请输入企业名称');
          return false
        } else if (enterprise.company_address == '') {
          _this.$toast('请输入公司地址');
          return false
        } else if (enterprise.telephone == '') {
          _this.$toast('请输入联系电话');
          return false
        } else if (!(/^1[34578]\d{9}$/.test(enterprise.telephone))) {
          _this.$toast('请输入正确的联系电话');
          return false
        } else if (enterprise.company_icode == '') {
          _this.$toast('请输入社会信用代码');
          return false
        } else if (enterprise.legal_name == '') {
          _this.$toast('请输入法人姓名');
          return false
        } else if (enterprise.id_card == '') {
          _this.$toast('请输入法人身份证号码');
          return false
        } else if (enterprise.account_bank == '') {
          _this.$toast('请输入开户银行');
          return false
        } else if (enterprise.account_num == '') {
          _this.$toast('请输入开户账号');
          return false
        } else if (_this.IDpositive.length == 0) {
          _this.$toast('请上传身份证正面照片');
          return false
        } else if (_this.IDside.length == 0) {
          _this.$toast('请上传身份证反面照片');
          return false
        } else if (_this.Business.length == 0) {
          _this.$toast('请上传营业执照照片');
          return false
        } else {
          myData.append("company_name", enterprise.company_name);
          myData.append("company_address", enterprise.company_address);
          myData.append("telephone", enterprise.telephone);
          myData.append("company_icode", enterprise.company_icode);
          myData.append("legal_name", enterprise.legal_name);
          myData.append("id_card", enterprise.id_card);
          myData.append("auth_photos", enterprise.auth_photos);
          myData.append("account_bank", enterprise.account_bank);
          myData.append("account_num", enterprise.account_num);
        }


      } else if (choice == 1) { //个体户
        url = "users/api.finance/update_company_auth";
        var businessCircles = _this.businessCircles;
        if (businessCircles.company_name == '') {
          _this.$toast('请输入个体户名称');
          return false;
        } else if (businessCircles.company_address == '') {
          _this.$toast('请输入经营地址');
          return false;
        } else if (businessCircles.telephone == '') {
          _this.$toast('请输入联系电话');
          return false;
        } else if (!(/^1[34578]\d{9}$/.test(businessCircles.telephone))) {
          _this.$toast('请输入正确的联系电话');
          return false;
        } else if (businessCircles.company_icode == '') {
          _this.$toast('请输入社会信用代码');
          return false;
        } else if (businessCircles.legal_name == '') {
          _this.$toast('请输入法人姓名');
          return false;
        } else if (businessCircles.id_card == '') {
          _this.$toast('请输入法人身份证号码');
          return false;
        } else if (businessCircles.account_bank == '') {
          _this.$toast('请输入开户银行');
          return false;
        } else if (businessCircles.account_num == '') {
          _this.$toast('请输入开户账号');
          return false;
        } else if (_this.IDpositive.length == 0) {
          _this.$toast('请上传身份证正面照片');
          return false;
        } else if (_this.IDside.length == 0) {
          _this.$toast('请上传身份证反面照片');
          return false;
        } else if (_this.Business.length == 0) {
          _this.$toast('请上传营业执照照片');
          return false;
        } else {
          myData.append("company_name", businessCircles.company_name);
          myData.append("company_address", businessCircles.company_address);
          myData.append("telephone", businessCircles.telephone);
          myData.append("company_icode", businessCircles.company_icode);
          myData.append("legal_name", businessCircles.legal_name);
          myData.append("id_card", businessCircles.id_card);
          myData.append("auth_photos", businessCircles.auth_photos);
          myData.append("account_bank", businessCircles.account_bank);
          myData.append("account_num", businessCircles.account_num);
        }


      } else if (choice == 2) { //无发票
        var personal = _this.personal;
        if (personal.real_name == '') {
          _this.$toast('请输入真实姓名');
          return false;
        } else if (personal.id_card == '') {
          _this.$toast('请输入身份证号');
          return false;
        } else if (personal.account_bank == '') {
          _this.$toast('请输入开户银行');
          return false;
        } else if (personal.account_num == '') {
          _this.$toast('请输入银行卡号');
          return false;
        } else if (personal.id_card_front == '') {
          _this.$toast('请上传身份证正面照');
          return false;
        } else if (personal.id_card_back == '') {
          _this.$toast('请上传身份证反面照');
          return false;
        } else if (_this.IDpositive.length == 0) {
          _this.$toast('请上传身份证正面照片');
          return false;
        } else if (_this.IDside.length == 0) {
          _this.$toast('请上传身份证反面照片');
          return false;
        } else if (personal.phone == '') {
          _this.$toast('请输入联系电话');
          return false;
        } else if (!(/^1[3456789]\d{9}$/.test(personal.phone))) {
          _this.$toast('请输入正确的联系电话');
          return false;
        } else {
          this.agreementShow = true
          this.Personal1 = 2
          return
        }
      }
      _this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });

      _this.$http({
        method: 'post',
        url: url,
        data: myData
      }).then((res) => {
        _this.$toast.clear();
        if (res.data.code == 0) {
          _this.$dialog.alert({
            title: '提示',
            message: '已提交，正在审核中',
          }).then(() => {
            _this.$router.push({
              path: "/realname/service/service"
            })
          });
        } else if (res.data.code == -100) {
          _this.$dialog.alert({
            title: '提示',
            message: '登录过期，请重新登录',
          }).then(() => {
            _this.$router.push({
              path: "/login"
            })
          });

        } else {
          _this.$toast(res.data.msg);
        }
      }).catch(() => {
        _this.$toast.clear();
        _this.$toast('网络请求失败');
      });
    },
    //删除
    onDelete() {
      var _this = this;
      _this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });
      var myData = new URLSearchParams();
      myData.append("openid", _this.openid);
      myData.append("login_token", _this.login_token);
      _this.$http({
        method: 'post',
        url: 'users/api.finance/canale_auth',
        data: myData
      }).then((res) => {
        _this.$toast.clear();
        if (res.data.code == 0) {
          _this.$dialog.alert({
            title: '提示',
            message: '取消认证成功',
          }).then(() => {
            _this.$router.push({
              path: "/realname/cooperation/mode"
            })
          });
        } else if (res.data.code == -100) {
          _this.$dialog.alert({
            title: '提示',
            message: '登录过期，请重新登录',
          }).then(() => {
            _this.$router.push({
              path: "/login"
            })
          });

        } else {
          _this.$toast(res.data.msg);
        }
      }).catch(() => {
        _this.$toast.clear();
        _this.$toast('网络请求失败');
      });
    },

  },
  created() {
    this.openid = this.$store.state.openid
  }
}
</script>

<style scoped="scoped">
/* 协议弹出框 */
.xieyiPopup {
  width: 100%;
  padding: .3rem;
  height: 90%;
  overflow: auto;
  box-sizing: border-box;
  position: relative;
}

.xieyitille {
  font-size: .36rem;
  line-height: 1rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.xieyiInfo {
  font-size: .26rem;
  margin-bottom: .2rem;
}

.xieyiCu {
  font-size: .3rem;
  font-weight: bold;
}

.xieyibtn {
  background: #fff;
  width: 100%;
  padding: .2rem 0;
  line-height: 1rem;
  text-align: center;
  font-size: .3rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10;
}

.xieyibtn .btn {
  background: rgba(241, 82, 35, 1);
  width: 6rem;
  line-height: .8rem;
  margin: 0 auto;
  border-radius: .1rem;
  color: #fff;
}

.modify-box-red {
  background-color: #F15223;
  color: #FFFFFF;
}

.modify-box-gray {
  border: 0.02rem solid #dcd3d3;
}

.modify-box {
  width: 2rem;
  height: 0.6rem;
  line-height: 0.6rem;
  text-align: center;
  border-radius: 999999px;
  font-size: 0.3rem;
}

.upload-sub {
  width: 5.86rem;
  height: 0.88rem;
  color: #FFFFFF;
  background-color: #F15223;
  border-radius: 999999px;
  margin: 0 auto;
  margin-top: 1rem;
  line-height: 0.88rem;
  text-align: center;
}

.frombox-upload-box-txt {
  font-size: 0.22rem;
  color: #D5D7DA;
  text-align: center;
  padding-top: 0.32rem;
}

.frombox-upload-box-s {
  padding-top: 0.48rem;
  text-align: center;
  color: #D5D7DA;
}

.bg-gray {
  background-color: #F6F6F6;
}

.frombox-upload-box {
  position: absolute;
  width: 2.52rem;
  height: 2.52rem;
  top: 0;
  left: 0;
}

.show {
  opacity: 0;
}

.frombox-upload {
  width: 2.52rem;
  height: 2.52rem;
  position: relative;
}

.flex {
  display: flex;
}

.frombox-red {
  color: #FD2020;
  font-size: 0.2rem;
}

.frombox-right {
  width: 4.5rem;
  height: 1rem;
  border: none;
  outline: none;
}

.frombox-left {
  width: 2.6rem;
  text-indent: 0.32rem;
}

.frombox {
  width: 100%;
  height: 1.1rem;
  border-bottom: 0.02rem solid #f0eeee;
  display: flex;
  align-items: center;
  font-size: 0.28rem;
}

.bg-white {
  background-color: #FFFFFF;
}

.pb {
  padding-bottom: 0.44rem;
}

.padding {
  padding: 0.3rem;
}

.header {
  font-size: 0.24rem;
  color: #c5c5c5;
  background-color: #f6f6f6;
}
</style>
